<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Seluruh Penduduk</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/citizen/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start" >
              <div class="">
                <button
                  class="btn mx-1 my-1"
                  v-bind:class="{
                    'btn-primary': btnActive.all,
                    'btn-outline-primary': !btnActive.all,
                  }"
                  @click="filterButton('all')"
                >
                  Semua
                </button>
                <button
                  class="btn mx-1 my-1"
                  v-bind:class="{
                    'btn-primary': btnActive.patriarch,
                    'btn-outline-primary': !btnActive.patriarch,
                  }"
                  @click="filterButton('patriarch')"
                >
                  Kepala Keluarga
                </button>
                <button
                  class="btn mx-1 my-1"
                  v-bind:class="{
                    'btn-primary': btnActive.is_move_0,
                    'btn-outline-primary': !btnActive.is_move_0,
                  }"
                  @click="filterButton('is_move_0')"
                >
                  Penduduk Tetap
                </button>
                <button
                  class="btn mx-1 my-1"
                  v-bind:class="{
                    'btn-primary': btnActive.is_move_1,
                    'btn-outline-primary': !btnActive.is_move_1,
                  }"
                  @click="filterButton('is_move_1')"
                >
                  Penduduk Pindah
                </button>
                <button
                  class="btn mx-1 my-1"
                  v-bind:class="{
                    'btn-primary': btnActive.is_move_2,
                    'btn-outline-primary': !btnActive.is_move_2,
                  }"
                  @click="filterButton('is_move_2')"
                >
                  Penduduk Sementara
                </button>
                <button
                  class="btn mx-1 my-1"
                  v-bind:class="{
                    'btn-primary': btnActive.is_dead,
                    'btn-outline-primary': !btnActive.is_dead,
                  }"
                  @click="filterButton('is_dead')"
                >
                  Penduduk Meninggal
                </button>
              </div>
            </div>
            <div class="row justify-content-between mt-n3">
              <div class="col-3">
                <!-- <treeselect
                  v-model="filter.urban_village_id"
                  :multiple="false"
                  placeholder="Pilih Dusun"
                  :options="urban_village"
                  @input="filterByUrbanVillage"
                /> -->
              </div>
              <div class="col-md-4">
                <b-input-group>
                  <b-form-input
                    type="text"
                    placeholder="Masukkan keyword"
                    v-model="filter.keyword"
                  ></b-form-input>
                  <template #append>
                    <b-button squared variant="success" @click="filterByKeyword"
                      >Cari</b-button
                    >
                    <b-button squared variant="danger" @click="resetFilter"
                      >Reset</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>
            <div class="row justify-content-start">
              <div class="col py-0">
                <!-- <b-button variant="danger" size="sm">
                  <i class="fas fa-file-pdf text-white px-0"></i>
                </b-button> -->
                <vue-excel-xlsx
                  :data="itemExcel"
                  :columns="columns"
                  :filename="`Daftar Penduduk`"
                  :sheetname="`Daftar Penduduk`"
                >
                  <b-button
                    variant="primary"
                    class="ml-1 mt-1"
                    v-b-tooltip.hover
                    title="Cetak Excel"
                    size="sm"
                  >
                    <i class="fas fa-file-excel text-white px-0"></i>
                  </b-button>
                </vue-excel-xlsx>
              </div>
            </div>

            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
              class="mt-3"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'action' ? '25%' : '' }"
                />
              </template>

              <template #cell(photo)="data">
                <div
                  class="
                    d-flex
                    justify-content-start
                    align-items-center
                    image-container
                  "
                >
                  <div class="image-input-wrapper" style="position: relative">
                    <img class="image" :src="data.item.photo" />
                  </div>
                </div>
              </template>

              <template #cell(action)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="showModal(data.item)"
                  ><i class="fas fa-eye px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  v-if="btnAccess"
                  @click="$router.push(`/citizen/edit/${data.item.id}`)"
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  v-if="btnAccess"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <ModalDetail :data="detail" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import ModalDetail from "@/view/components/citizen/ModalDetail.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    ModalDetail,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        keyword: "",
        district: "",
        patriarch: "",
        is_move: "",
        is_dead: "",
        urban_village_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 60,
      columns: [
        {
          field: "number",
          label: "No",
        },
        {
          field: "id_card_number",
          label: "NIK",
        },
        {
          field: "name",
          label: "Nama",
        },
        {
          field: "gender_display",
          label: "Kelamin",
        },
        {
          field: "birt_place_date",
          label: "Tempat & Tanggal Lahir",
        },
        {
          field: "family_number",
          label: "Nomor KK",
        },
        {
          field: "address",
          label: "Alamat",
        },
        {
          field: "citizenship",
          label: "Kewarganegaraan",
        },
        {
          field: "is_married_display",
          label: "Status Perkawinan",
        },
        {
          field: "religion",
          label: "Agama",
        },
        {
          field: "blood_type",
          label: "Golongan Darah",
        },
        {
          field: "last_education",
          label: "Pendidikan Terakhir",
        },
        {
          field: "profession",
          label: "Pekerjaan",
        },
        {
          field: "special_needs",
          label: "Kebutuhan Khusus",
        },
        {
          field: "father_name",
          label: "Nama Ayah",
        },
        {
          field: "mother_name",
          label: "Nama Ibu",
        },
      ],
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "id_card_number",
          label: "NIK",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "gender_display",
          label: "Kelamin",
          sortable: true,
        },
        {
          key: "birt_date",
          label: "Tempat & Tanggal Lahir",
          sortable: true,
        },
        {
          key: "family_number",
          label: "Nomor KK",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      itemExcel: [],
      districts: [
        { value: "", text: "Semua Dusun" },
        { value: "0", text: "Dusun A" },
        { value: "1", text: "Dusun B" },
        { value: "2", text: "Dusun C" },
      ],
      urban_village: [],
      detail: {},
      btnActive: {
        all: true,
        patriarch: false,
        is_move_0: false,
        is_move_1: false,
        is_move_2: false,
        is_dead: false,
      },
      // access
      btnAccess: true,
    };
  },
  methods: {
    showModal(data) {
      this.$bvModal.show("detail-citizen");
      this.detail = data;
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `keyword=${this.filter.keyword}&urban_village_id=${this.filter.urban_village_id}&patriarch=${this.filter.patriarch}&is_move=${this.filter.is_move}&is_dead=${this.filter.is_dead}`;
      let response = await module.paginate(
        "citizen",
        `?page=${this.currentPage}&page_size=${this.perPage}&${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;

      // export
      let responseExcel = await module.get("citizen", `?${filterParams}`);
      this.itemExcel = responseExcel;
    },

    async getUrbanVillageOption() {
      let response = await module.setTreeSelect("urban-village");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.urban_village = response.data;
        this.urban_village.unshift({
          label: "Pilih Dusun",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByKeyword() {
      this.pagination();
    },
    resetFilter() {
      (this.filter.keyword = ""),
        (this.filter.district = ""),
        (this.filter.patriarch = ""),
        (this.filter.is_move = ""),
        (this.filter.is_dead = ""),
        (this.filter.urban_village_id = ""),
        (this.btnActive.all = true);
      this.btnActive.patriarch = false;
      this.btnActive.is_move_0 = false;
      this.btnActive.is_move_1 = false;
      this.btnActive.is_move_2 = false;
      this.btnActive.is_dead = false;

      this.pagination();
    },

    filterByUrbanVillage(evt) {
      if (typeof evt == "undefined") {
        this.filter.urban_village_id = "";
      }

      this.pagination();
    },

    filterButton(filterType) {
      if (filterType == "patriarch") {
        this.filter.patriarch = 1;
        this.filter.is_move = "";
        this.filter.is_dead = "";

        this.btnActive.all = false;
        this.btnActive.patriarch = true;
        this.btnActive.is_move_0 = false;
        this.btnActive.is_move_1 = false;
        this.btnActive.is_move_2 = false;
        this.btnActive.is_dead = false;
      } else if (filterType == "is_move_0") {
        this.filter.patriarch = "";
        this.filter.is_move = 0;
        this.filter.is_dead = "";

        this.btnActive.all = false;
        this.btnActive.patriarch = false;
        this.btnActive.is_move_0 = true;
        this.btnActive.is_move_1 = false;
        this.btnActive.is_move_2 = false;
        this.btnActive.is_dead = false;
      } else if (filterType == "is_move_1") {
        this.filter.patriarch = "";
        this.filter.is_move = 1;
        this.filter.is_dead = "";

        this.btnActive.all = false;
        this.btnActive.patriarch = false;
        this.btnActive.is_move_0 = false;
        this.btnActive.is_move_1 = true;
        this.btnActive.is_move_2 = false;
        this.btnActive.is_dead = false;
      } else if (filterType == "is_move_2") {
        this.filter.patriarch = "";
        this.filter.is_move = 2;
        this.filter.is_dead = "";

        this.btnActive.all = false;
        this.btnActive.patriarch = false;
        this.btnActive.is_move_0 = false;
        this.btnActive.is_move_1 = false;
        this.btnActive.is_move_2 = true;
        this.btnActive.is_dead = false;
      } else if (filterType == "is_dead") {
        this.filter.patriarch = "";
        this.filter.is_move = "";
        this.filter.is_dead = 1;

        this.btnActive.all = false;
        this.btnActive.patriarch = false;
        this.btnActive.is_move_0 = false;
        this.btnActive.is_move_1 = false;
        this.btnActive.is_move_2 = false;
        this.btnActive.is_dead = true;
      } else {
        this.filter.patriarch = "";
        this.filter.is_move = "";
        this.filter.is_dead = "";

        this.btnActive.all = true;
        this.btnActive.patriarch = false;
        this.btnActive.is_move_0 = false;
        this.btnActive.is_move_1 = false;
        this.btnActive.is_move_2 = false;
        this.btnActive.is_dead = false;
      }

      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("users/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Data Kependudukan", route: "/citizen/list" },
      { title: "Daftar Penduduk" },
    ]);

    this.pagination();
    this.getUrbanVillageOption();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>