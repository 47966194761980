<template>
  <div>
    <b-modal id="detail-citizen" size="lg" hide-footer @hide="reset">
      <template #modal-title> <b>DATA PENDUDUK</b> </template>
      <table class="table">
        <tr>
          <td>Nomor KK</td>
          <td>:</td>
          <td>{{ data.family_number }}</td>
        </tr>
        <tr>
          <td>KK</td>
          <td>:</td>
          <td>{{ data.id_card_number }}</td>
        </tr>
        <tr>
          <td>Nama</td>
          <td>:</td>
          <td>{{ data.name }}</td>
        </tr>
        <tr>
          <td>Jenis Kelamin</td>
          <td>:</td>
          <td>{{ data.gender_display }}</td>
        </tr>
        <tr>
          <td>Tempat & Tanggal Lahir</td>
          <td>:</td>
          <td>{{ data.birt_place }} ,{{ data.birt_date_display }}</td>
        </tr>
        <tr>
          <td>Alamat</td>
          <td>:</td>
          <td>{{ data.address }}</td>
        </tr>
        <tr>
          <td>Kewarganegaraan</td>
          <td>:</td>
          <td>{{ data.citizenship }}</td>
        </tr>
        <tr>
          <td>Status Perkawinan</td>
          <td>:</td>
          <td>{{ data.is_married_display }}</td>
        </tr>
        <tr>
          <td>Agama</td>
          <td>:</td>
          <td>{{ data.religion }}</td>
        </tr>
        <tr>
          <td>Golongan Darah</td>
          <td>:</td>
          <td>{{ data.blood_type }}</td>
        </tr>
        <tr>
          <td>Pendidikan Terakhir</td>
          <td>:</td>
          <td>{{ data.last_education }}</td>
        </tr>
        <tr>
          <td>Pekerjaan</td>
          <td>:</td>
          <td>{{ data.profession }}</td>
        </tr>
        <tr>
          <td>Kebutuhan Khusus</td>
          <td>:</td>
          <td>{{ data.special_needs }}</td>
        </tr>
        <tr>
          <td>Nama Ayah</td>
          <td>:</td>
          <td>{{ data.father_name }}</td>
        </tr>
        <tr>
          <td>Nama Ibu</td>
          <td>:</td>
          <td>{{ data.mother_name }}</td>
        </tr>
      </table>

      <b-table
        striped
        hover
        class="mt-3"
        :items="items"
        :fields="fields"
        :style="'white-space: nowrap'"
        responsive="sm"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '20%' : '' }"
          />
        </template>
        <template #cell(range_male)="data">
          <span>{{
            data.item.start_range_male +
            " - " +
            data.item.end_range_male +
            " " +
            data.item.uom
          }}</span>
        </template>
        <template #cell(range_female)="data">
          <span>{{
            data.item.start_range_female +
            " - " +
            data.item.end_range_female +
            " " +
            data.item.uom
          }}</span>
        </template>

        <template #cell(letter_status_display)="data">
          <b-badge
            style="cursor: pointer; background: #b5c6ff"
            class="btn"
            v-if="data.item.letter_status_id == 1"
            @click="editLetterStatus(data.item)"
            >{{ data.item.letter_status_id_display }}</b-badge
          >
          <b-badge
            style="cursor: pointer"
            class="btn btn-danger"
            v-if="data.item.letter_status_id == 2"
            @click="editLetterStatus(data.item)"
            >{{ data.item.letter_status_id_display }}</b-badge
          >
          <b-badge
            style="cursor: pointer; background: #088346"
            class="btn text-white"
            v-if="data.item.letter_status_id == 3"
            @click="editLetterStatus(data.item)"
            >{{ data.item.letter_status_id_display }}</b-badge
          >
          <b-badge
            style="cursor: pointer; background: #d863b9"
            class="btn text-white"
            v-if="data.item.letter_status_id == 4"
            @click="editLetterStatus(data.item)"
            >{{ data.item.letter_status_id_display }}</b-badge
          >
          <b-badge
            style="cursor: pointer; background: #95ede2"
            class="btn"
            v-if="data.item.letter_status_id == 5"
            @click="editLetterStatus(data.item)"
            >{{ data.item.letter_status_id_display }}</b-badge
          >
        </template>

        <template #cell(actions)="data">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              $router.push(setRoute(data.item.letter_type_id) + data.item.id,)
            "
            ><i class="fas fa-eye px-0"></i
          ></b-button>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage" @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </b-modal>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  props: {
    data: Object,
  },
  data() {
    return {
      // Pagination
      perPage: 5,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number_table",
          label: "#",
          sortable: true,
        },
        {
          key: "number",
          label: "No. Surat",
          sortable: true,
        },
        {
          key: "registered_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "title",
          label: "Surat",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
    };
  },
  methods: {
    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
      console.log('page onclick')
    },

    async pagination() {
      let filterParams = `&citizen_id=${this.data.id}`;
      let response = await module.paginate(
        "letters-history",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number_table = b + a;
      }

      this.items = response.data;
    },

    setRoute(letter_type_id){
      let route = ''
      if(letter_type_id == 1){
        route = '/poverty-certificate/detail/'
      }else if(letter_type_id == 2){
        route = '/death-certificate/detail/'
      }else if(letter_type_id == 3){
        route = '/bussiness-certificate/detail/'
      }else if(letter_type_id == 4){
        route = '/transfer-certificate/detail/'
      }else if(letter_type_id == 5){
        route = '/birth-certificate/detail/'
      }else if(letter_type_id == 6){
        route = '/unmarried-certificate/detail/'
      }else if(letter_type_id == 7){
        route = '/bisnis-place-certificate/detail/'
      }else if(letter_type_id == 8){
        route = '/resident-certificate/detail/'
      }else if(letter_type_id == 9){
        route = '/cover-letter/detail/'
      }else if(letter_type_id == 10){
        route = '/good-conduct-certificate/detail/'
      }else if(letter_type_id == 11){
        route = '/health-insurance-certificate/detail/'
      }else if(letter_type_id == 12){
        route = '/jkbm-certificate/detail/'
      }
      console.log('letter_type_id', letter_type_id)
      console.log('route', route)
      return route
    },

    reset(){
      // this.items = []
      // console.log('reset item', this.items)
    }
  },
  mounted() {
    // this.pagination();
    this.$root.$on("closeModalDetail", () => {
      console.log('receipt emit close table')
    });
  },
  watch:{
    data: function(newVal, oldVal) {
      this.pagination()
    },
  }
};
</script>

<style>
</style>